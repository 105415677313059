import styled from "styled-components/macro";
import { misc, media } from "../../styles/constants";

export const Container = styled.div`
  display: none;

  ${media.md} {
    position: fixed;
    left: 13px;
    right: 13px;
    bottom: 13px;
    bottom: calc(constant(safe-area-inset-bottom) + 15px);
    bottom: calc(env(safe-area-inset-bottom) + 15px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background: rgba(23, 23, 23, 0.7);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(8px);
  border-radius: 46px;
  transition: ${misc.transition};
`;
