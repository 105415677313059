import React from "react";
import Social from "../Social";
import {
  Container,
  LogoLink,
  Logo,
  Nav,
  NavLink,
  Contacts,
  PhoneLink,
} from "./styles";

const Header: React.FC = () => (
  <Container>
    <Nav>
      <NavLink to="/">About me</NavLink>
      <NavLink to="/">Studio</NavLink>
      <NavLink to="/courses">School</NavLink>
      <NavLink to="/">Shop</NavLink>
      <NavLink to="/">Contacts</NavLink>
    </Nav>
    <LogoLink to="/">
      <Logo />
    </LogoLink>
    <Contacts>
      <Social />
      <PhoneLink href="tel:+1 (564) 208-6567">+1 (564) 208-6567</PhoneLink>
    </Contacts>
  </Container>
);

export default Header;
