import styled from "styled-components/macro";
import Social from "../Social";
import TextLink, { StyledLink, Arrow as TextLinkArrow } from "../TextLink";
import { media } from "../../styles/constants";

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #1f1f1f;
  padding: 25px 0 80px;

  ${media.md} {
    border: none;
    padding: 0 0 60px;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;

  ${media.md} {
    display: none;
  }
`;

export const InfoNav = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;

  ${StyledLink} {
    color: #727272;

    ${TextLinkArrow} {
      path {
        fill: #727272;
      }
    }

    &:hover {
      color: #fff;

      ${TextLinkArrow} {
        path {
          fill: #fff;
        }
      }
    }
  }
`;

export const InfoLink = styled(TextLink)`
  &:not(:last-child) {
    margin-right: 38px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;

  ${media.md} {
    justify-content: center;
    margin-top: 0;
  }
`;

export const Copyrights = styled.div`
  margin-right: 50px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #757575;

  ${media.md} {
    margin-right: 0;
    text-align: center;
  }
`;

export const Info = styled.div`
  margin-right: auto;
  max-width: 460px;
  font-weight: 400;
  font-size: 12px;
  line-height: 170.52%;
  color: #3d3d3d;

  ${media.md} {
    display: none;
  }
`;

export const SocialLinks = styled(Social)`
  ${media.md} {
    display: none;
  }
`;
