import React, { useRef } from "react";
import MainTemplate from "../../templates/Main";
import { FreeMode, Navigation, Thumbs, Swiper as SwiperRef } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ReactComponent as ArrowBackIcon } from "../../assets/images/icons/arrow-back.svg";
import { ReactComponent as GiftIcon } from "../../assets/images/icons/gift.svg";
import { ReactComponent as SaleIcon } from "../../assets/images/icons/sale.svg";
import { ReactComponent as PaintBrushIcon } from "../../assets/images/icons/paint-brush.svg";
import {
  Page,
  Header,
  Hero,
  Top,
  BackLink,
  Date,
  Title,
  Discount,
  ShortText,
  Price,
  OldPrice,
  BuyButton,
  Content,
  Column,
  SubTitle,
  FullText,
  Features,
  Feature,
  Plan,
  PlanItem,
  PlanDay,
  PlanText,
  BonusesSubTitle,
  Bonuses,
  BonusCard,
  BonusText,
  BonusDiscount,
  Certificate,
  CertificateImg,
  CertificateText,
  Gallary,
  GallaryMain,
  GallaryThumbs,
} from "./styles";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

type Course = {
  slug: string;
  title: string;
  shortText: string;
  fullText: string;
  date: string;
  price: number;
  oldPrice: number;
  discount: number;
  features: string[];
  plan: string[];
  bonus: string;
  galleryCollection: {
    items: {
      title: string;
      url: string;
    }[];
  };
};

type CourseCollection = {
  items: Course[];
};

type CourseCollectionQuery = {
  courseCollection: CourseCollection;
};

const courseFragment = gql`
  fragment CourseFragment on Course {
    slug
    title
    shortText
    fullText
    date
    price
    oldPrice
    discount
    features
    plan
    bonus
    galleryCollection {
      items {
        url
        title
      }
    }
  }
`;

const courseBySlug = gql`
  query CourseBySlug($slug: String!) {
    courseCollection(where: { slug: $slug }) {
      items {
        ...CourseFragment
      }
    }
  }
  ${courseFragment}
`;

const CoursePage: React.FC = () => {
  const thumbsSwiper = useRef<SwiperRef>();
  const { slug } = useParams<{ slug: string }>();
  const { data } = useQuery<CourseCollectionQuery>(courseBySlug, {
    variables: { slug },
  });
  const course = data?.courseCollection.items?.[0];

  return (
    <MainTemplate>
      <Page>
        <Header>
          <Hero>
            <Top>
              <BackLink to="/courses">
                <ArrowBackIcon />
                Go back
              </BackLink>
              <Date>{course?.date}</Date>
            </Top>
            <Title>
              {course?.title}
              <Discount>{course?.discount}%</Discount>
            </Title>
            <ShortText>{course?.shortText}</ShortText>
            <Price>
              {course?.price}$ <OldPrice>{course?.oldPrice}$</OldPrice>
            </Price>
            <BuyButton to="#">Buy online</BuyButton>
          </Hero>

          <Gallary>
            <GallaryMain>
              <Swiper
                spaceBetween={10}
                thumbs={{ swiper: thumbsSwiper.current }}
                modules={[FreeMode, Navigation, Thumbs]}
              >
                {course?.galleryCollection.items.map((item) => (
                  <SwiperSlide>
                    <img src={item.url} alt={item.title} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </GallaryMain>
            <GallaryThumbs>
              <Swiper
                onSwiper={(swiper) => (thumbsSwiper.current = swiper)}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
              >
                {course?.galleryCollection.items.map((item) => (
                  <SwiperSlide>
                    <img src={item.url} alt={item.title} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </GallaryThumbs>
          </Gallary>
        </Header>

        <Content>
          <Column>
            <SubTitle>Course Description</SubTitle>
            <FullText>{course?.fullText}</FullText>
            <Features>
              {course?.features?.map((feature) => (
                <Feature key={feature}>{feature}</Feature>
              ))}
            </Features>
          </Column>

          <Column>
            <SubTitle>How is the training going?</SubTitle>
            <Plan>
              {course?.plan?.map((plan, index) => (
                <PlanItem key={plan}>
                  <PlanDay>Day {index + 1}</PlanDay>
                  <PlanText>{plan}</PlanText>
                </PlanItem>
              ))}
            </Plan>
          </Column>
        </Content>

        <BonusesSubTitle>
          Course bonus
          <GiftIcon />
        </BonusesSubTitle>
        <Bonuses>
          <BonusCard>
            <GiftIcon />
            <BonusText>{course?.bonus}</BonusText>
          </BonusCard>
          <BonusCard>
            <SaleIcon />
            <BonusText>
              Fixed discount <BonusDiscount>-10%</BonusDiscount> for the
              purchase of high-quality products in our store!
            </BonusText>
          </BonusCard>
          <BonusCard>
            <PaintBrushIcon />
            <BonusText>
              At the time of training, the school provides premium class
              materials and the necessary equipment!
            </BonusText>
          </BonusCard>
        </Bonuses>
        <Certificate>
          <CertificateImg />
          <CertificateText>
            A methodological manual and a certificate of successful completion
            of the course are issued!
          </CertificateText>
        </Certificate>
      </Page>
    </MainTemplate>
  );
};

export default CoursePage;
