import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { reviews } from "./constants";
import {
  Container,
  Slide,
  Image,
  Content,
  Title,
  Name,
  Reaction,
  Body,
  Text,
  Info,
  Course,
} from "./styles";
import "swiper/css";

const Reviews: React.FC = () => (
  <Container>
    <Title>Reviews about me</Title>
    <Swiper
      pagination={{ clickable: true }}
      slidesPerView="auto"
      spaceBetween={100}
      modules={[Pagination]}
    >
      {reviews.map((review, index) => (
        <SwiperSlide key={index}>
          <Slide>
            <Image src={review.pic} />
            <Content>
              <Name>{review.name}</Name>
              <Body>
                <Info>
                  Course: <Course>{review.course}</Course>
                </Info>
                <Text>{review.text}</Text>
                <Reaction src={review.reaction} />
              </Body>
            </Content>
          </Slide>
        </SwiperSlide>
      ))}
    </Swiper>
  </Container>
);

export default Reviews;
