import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { routes } from "./routes/constants";
import { GlobalStyle } from "./styles";

const SPACE_ID = "0y9pm4qn6yxy";
const ACCESS_TOKEN = "tD6_80K3kdEAH80PkD6lh1-CFL6c7cxLg90E1ekb5HM";

const router = createBrowserRouter(routes);
const cache = new InMemoryCache();
const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}`,
  cache,
  credentials: "same-origin",
  headers: {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  },
});

const App: React.FC = () => {
  return (
    <>
      <ApolloProvider client={client}>
        <RouterProvider router={router} />
        <GlobalStyle />
      </ApolloProvider>
    </>
  );
};

export default App;
