import styled from "styled-components/macro";
import logoImg from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Logo = styled.img.attrs({
  src: logoImg,
})`
  margin-bottom: 50px;
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #fff;
`;

export const HomeLink = styled(Link)`
  margin-top: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  text-transform: uppercase;
`;
