import React from "react";
import { Page, Logo, Title, HomeLink } from "./styles";

const NotFoundPage: React.FC = () => {
  return (
    <Page>
      <Logo />
      <Title>Страница не найдена</Title>
      <HomeLink to="/">На главную</HomeLink>
    </Page>
  );
};

export default NotFoundPage;
