import React from "react";
import { StyledLink, Arrow } from "./styles";

type TextLinkProps = {
  className?: string;
  to: string;
  text: string;
};

const TextLink: React.FC<TextLinkProps> = ({ className, to, text }) => (
  <StyledLink className={className} to={to}>
    {text}
    <Arrow />
  </StyledLink>
);

export default TextLink;
