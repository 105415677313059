import React from "react";
import { ReactComponent as InstagramIcon } from "../../assets/images/icons/instagram.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/icons/phone.svg";
import { Container, Link } from "./styles";

type MobileMenuProps = {
  className?: string;
};

const MobileMenu: React.FC<MobileMenuProps> = ({ className }) => (
  <Container className={className}>
    <Link href="/">
      <InstagramIcon />
    </Link>
    <Link href="tel:+1 (564) 208-6567">
      <PhoneIcon />
    </Link>
  </Container>
);

export default MobileMenu;
