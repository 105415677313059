import styled, { css } from "styled-components/macro";
import bgImg from "../../assets/images/bg.png";
import { ContainerProps } from "./types";

export const Container = styled.div<ContainerProps>`
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-position: 60% -300px;

  ${({ bgImg }) =>
    bgImg &&
    css`
      background-image: url(${bgImg});
    `}
`;

export const Inner = styled.div`
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const Body = styled.div`
  position: relative;
  padding: 50px 0;
`;
