import styled from "styled-components/macro";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/link-arrow.svg";
import { Link } from "react-router-dom";
import { color, misc } from "../../styles/constants";

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 34px;
  color: #cacaca;
  text-decoration: none;
  transition: ${misc.transition};

  &:hover {
    color: ${color.white};
  }
`;

export const Arrow = styled(ArrowIcon)`
  margin-left: 10px;

  path {
    fill: #30735b;
    transition: ${misc.transition};
  }
`;
