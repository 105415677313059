import styled from "styled-components/macro";
import { media } from "../../styles/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .swiper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: visible;
  }

  .swiper-slide {
    width: calc(50% - 50px);
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
  }

  .swiper-pagination-bullet {
    display: block;
    width: 44px;
    height: 3px;
    margin: 0 9px;
    background: rgba(217, 217, 217, 0.09);
  }

  .swiper-pagination-bullet-active {
    background: #d9d9d9;
  }

  ${media.md} {
    .swiper-slide {
      width: 100%;
    }
  }
`;

export const Slide = styled.div`
  display: flex;
  align-items: flex-start;

  ${media.md} {
    position: relative;
    flex-direction: column;
    padding: 20px 24px;
    background: #151515;
  }
`;

export const Image = styled.img`
  width: 96px;
  height: 96px;
  margin-right: 24px;

  ${media.md} {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 24px;
    left: 24px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 60px;
  font-weight: 500;
  font-size: 36px;
  line-height: 118.5%;
  color: #dbdbdb;

  ${media.md} {
    font-size: 26px;
    text-align: center;
  }
`;

export const Content = styled.div``;

export const Name = styled.h3`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 19px;
  line-height: 198.5%;
  color: #dddddd;

  ${media.md} {
    margin-top: 4px;
    margin-left: 54px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.3;
  }
`;

export const Body = styled.div`
  position: relative;
  padding: 26px 60px 26px 26px;
  background: #151515;

  ${media.md} {
    padding: 0;
    background: none;
    position: static;
  }
`;

export const Info = styled.div`
  margin-bottom: 12px;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 198.5%;
  color: #808080;
  border-bottom: 1px solid #1f1f1f;

  ${media.md} {
    margin-left: 54px;
    margin-bottom: 0;
    font-size: 13px;
    border: none;
  }
`;

export const Course = styled.span`
  color: #dddddd;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 198.5%;
  color: #848484;

  ${media.md} {
    font-size: 14px;
  }
`;

export const Reaction = styled.img`
  position: absolute;
  top: 0;
  right: 80px;
  width: 64px;
  height: 64px;
  transform: translateY(-50%) rotate(15deg);

  ${media.md} {
    top: 10px;
    right: 10px;
    zoom: 0.8;
  }
`;
