import styled from "styled-components/macro";
import starburstImg from "../../assets/images/icons/starburst.svg";
import certificateImg from "../../assets/images/certificate.svg";
import { Link } from "react-router-dom";
import { media, misc } from "../../styles/constants";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  ${media.md} {
    flex-direction: column;
  }
`;

export const Gallary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 880px;
`;

export const GallaryMain = styled.div`
  display: flex;
  width: 100%;

  .swiper-slide {
    display: flex;
  }

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  ${media.md} {
    img {
      height: 220px;
    }
  }
`;

export const GallaryThumbs = styled.div`
  display: flex;
  margin-top: 10px;

  .swiper-slide {
    display: flex;
  }

  .swiper-slide-thumb-active {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 2px #707070 inset;
    }
  }

  img {
    width: 100%;
    height: 126px;
    object-fit: cover;
  }

  ${media.md} {
    img {
      height: 50px;
    }
  }
`;

export const Hero = styled.div`
  margin-bottom: 100px;

  ${media.md} {
    margin-bottom: 40px;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 56px;
`;

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 62px;
  padding: 3px 30px 0 20px;
  margin-right: 52px;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
  text-decoration: none;
  transition: ${misc.transition};
  border: 2px solid rgba(43, 43, 43, 0.38);

  svg {
    margin-right: 20px;

    path {
      transition: ${misc.transition};
    }
  }

  &:hover {
    border-color: rgba(60, 60, 60, 1);

    svg {
      path {
        fill: rgba(60, 60, 60, 1);
      }
    }
  }

  ${media.md} {
    height: 48px;
    margin-right: 20px;
    padding: 3px 16px 0 10px;
    font-size: 16px;
    line-height: 15px;

    svg {
      margin-right: 10px;
    }
  }
`;

export const Date = styled.div`
  margin-top: 4px;
  font-weight: 300;
  font-size: 33px;
  line-height: 32px;
  color: #4ca787;

  ${media.md} {
    font-size: 24px;
    line-height: 23px;
  }
`;

export const Title = styled.h1`
  position: relative;
  max-width: 520px;
  font-weight: 300;
  font-size: 71px;
  line-height: 69px;
  color: #ffffff;

  ${media.md} {
    font-size: 48px;
    line-height: 47px;
  }
`;

export const Discount = styled.div`
  position: absolute;
  right: 20px;
  bottom: 40px;
  display: inline-flex;
  padding: 12px 8px 6px;
  font-weight: 500;
  font-size: 32px;
  line-height: 31px;
  color: #000000;
  background: #dfcf40;
  transform: rotate(20.55deg);

  ${media.md} {
    right: 10px;
    bottom: 20px;
    padding: 8px 6px 4px;
    font-size: 24px;
    line-height: 23px;
  }
`;

export const ShortText = styled.p`
  max-width: 620px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 188.4%;
  color: #737373;
`;

export const Price = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  font-weight: 500;
  font-size: 32px;
  line-height: 31px;
  color: #377d64;

  ${media.md} {
    margin-top: 30px;
  }
`;

export const OldPrice = styled.span`
  margin-left: 8px;
  font-weight: 500;
  font-size: 23px;
  line-height: 22px;
  color: #dfcf40;
  text-decoration: line-through;
`;

export const BuyButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  height: 62px;
  margin-top: 60px;
  padding: 3px 30px 0;
  margin-right: 52px;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
  text-decoration: none;
  transition: ${misc.transition};
  border: 2px solid #377d64;

  &:hover {
    border-color: #4ca787;
  }

  ${media.md} {
    margin-top: 40px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin-bottom: 100px;

  ${media.md} {
    grid-template-columns: 1fr;
    margin-bottom: 40px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.h2`
  margin-bottom: 20px;
  padding-bottom: 30px;
  font-weight: 300;
  font-size: 33px;
  line-height: 32px;
  color: #ffffff;
  border-bottom: 2px solid #252525;

  ${media.md} {
    margin-bottom: 16px;
    padding-bottom: 20px;
    font-size: 24px;
    line-height: 23px;
  }
`;

export const FullText = styled.p`
  font-weight: 500;
  font-size: 17px;
  line-height: 188.4%;
  color: #b5b5b5;
`;

export const Features = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 30px;

  ${media.md} {
    grid-template-columns: 1fr;
  }
`;

export const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  font-size: 17px;
  line-height: 140%;
  color: #b5b5b5;

  &::before {
    content: "";
    display: inline-block;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: url(${starburstImg}) no-repeat center;
    background-size: contain;
  }

  ${media.md} {
    font-size: 16px;
  }
`;

export const Plan = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlanItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-bottom: 14px;
  border-bottom: 2px solid #252525;
`;

export const PlanDay = styled.h3`
  width: 110px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 28px;
  line-height: 227.9%;
  color: #f4f4f4;
`;

export const PlanText = styled.p`
  padding: 6px 0;
  font-weight: 500;
  font-size: 17px;
  line-height: 227.9%;
  color: #b5b5b5;

  ${media.md} {
    font-size: 16px;
    line-height: 180%;
  }
`;

export const BonusesSubTitle = styled.h2`
  display: flex;
  align-items: flex-start;
  padding-bottom: 30px;
  font-weight: 300;
  font-size: 33px;
  line-height: 32px;
  color: #ffffff;

  svg {
    width: 26px;
    height: 26px;
    margin-left: 10px;

    path {
      fill: #fff;
    }
  }

  ${media.md} {
    padding-bottom: 20px;
    font-size: 24px;
    line-height: 23px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Bonuses = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 6px;

  ${media.md} {
    grid-template-columns: 1fr;
  }
`;

export const BonusCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 355px;
  padding: 40px;
  background-color: #161616;

  svg {
    height: 54px;
    margin-bottom: 30px;
  }

  ${media.md} {
    min-height: auto;
    padding: 26px;
  }
`;

export const BonusText = styled.p`
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: #ffffff;

  ${media.md} {
    font-size: 24px;
  }
`;

export const BonusDiscount = styled.span`
  display: inline-block;
  color: #000000;
  margin-top: -4px;
  padding: 4px 4px 0;
  background-color: #dfcf40;
`;

export const Certificate = styled.div`
  display: flex;
  align-items: center;
  height: 370px;
  margin-top: 6px;
  margin-bottom: 40px;
  padding: 40px;
  background-color: #161616;

  ${media.md} {
    height: auto;
    flex-direction: column;
    margin-bottom: 26px;
    padding: 26px;
  }
`;

export const CertificateImg = styled.img.attrs({
  src: certificateImg,
})`
  margin-right: 40px;
  transform: translateY(10%);

  ${media.md} {
    width: 100%;
    margin-right: 0;
    transform: scale(1.2);
  }
`;

export const CertificateText = styled.p`
  max-width: 700px;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: #ffffff;

  ${media.md} {
    margin-top: 70px;
    font-size: 24px;
    text-align: center;
  }
`;
