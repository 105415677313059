import React from "react";
import {
  Container,
  Top,
  InfoNav,
  InfoLink,
  Bottom,
  Copyrights,
  Info,
  SocialLinks,
} from "./styles";

const Footer: React.FC = () => (
  <Container>
    <Top>
      <InfoNav>
        <InfoLink to="/terms-of-use" text="Terms of Use" />
        <InfoLink to="/privacy-policy" text="Privacy Policy" />
        <InfoLink to="/honesty" text="Honesty" />
      </InfoNav>
    </Top>
    <Bottom>
      <Copyrights>
        Copyright © 2022-2023 Qualymade.com
        <br />
        All Rights Reserved.
      </Copyrights>
      <Info>
        QUALYMADE LTD: Company number 13246765, 16 John Nicholas Crescent,
        Ellesmere Port, Cheshire, United Kingdom, CH65 2DL, +44 07308278693.
      </Info>
      <SocialLinks />
    </Bottom>
  </Container>
);

export default Footer;
