import React from "react";
import { ReactComponent as YouTubeIcon } from "../../assets/images/icons/youtube.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/icons/instagram.svg";
import { ReactComponent as TelegramIcon } from "../../assets/images/icons/telegram.svg";
import { Container, Link } from "./styles";

type SocialProps = {
  className?: string;
};

const Social: React.FC<SocialProps> = ({ className }) => (
  <Container className={className}>
    <Link href="/">
      <YouTubeIcon />
    </Link>
    <Link href="/">
      <InstagramIcon />
    </Link>
    <Link href="/">
      <TelegramIcon />
    </Link>
  </Container>
);

export default Social;
