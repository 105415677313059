import styled, { css } from "styled-components/macro";
import photoImg from "../../assets/images/photo.jpg";
import signatureImg from "../../assets/images/signature.svg";
import { media } from "../../styles/constants";
import { StyledLink } from "../../components/TextLink";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Intro = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 110px;

  ${media.md} {
    margin-bottom: 50px;
  }
`;

export const IntroBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 50%;
  padding-right: 20px;

  ${media.md} {
    width: 100%;
    padding-right: 0;
  }
`;

export const IntroTitle = styled.h1`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 48px;
  line-height: 1.3;

  ${media.md} {
    font-size: 30px;
  }
`;

export const IntroTitleStart = styled.span`
  font-size: 36px;

  ${media.md} {
    font-size: 22px;
  }
`;

export const IntroText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  font-weight: 500;
  font-size: 18px;
  line-height: 198.5%;
  color: #848484;

  ${StyledLink} {
    margin-top: 20px;
  }

  ${media.md} {
    margin-top: 30px;
    font-size: 15px;

    ${StyledLink} {
      margin-top: 16px;
    }
  }
`;

export const IntroSignature = styled.img.attrs({
  src: signatureImg,
})`
  width: 300px;
  margin-top: 20px;
  margin-left: auto;

  ${media.md} {
    position: absolute;
    top: -80px;
    left: calc(50% - 100px);
    width: 200px;
  }
`;

export const IntroPhoto = styled.img.attrs({
  src: photoImg,
})``;

export const IntroDesktopPhoto = styled(IntroPhoto)`
  width: 50%;
  flex-shrink: 0;

  ${media.md} {
    display: none;
  }
`;

export const IntroMobilePhoto = styled(IntroPhoto)`
  display: none;

  ${media.md} {
    display: block;
    width: calc(100% + 6px * 2);
    margin-left: -6px;
  }
`;

export const Stars = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #2c2c2c;
`;

type StarsRowProps = {
  big?: boolean;
};

export const StarsRow = styled.div<StarsRowProps>`
  display: flex;
  width: 100%;

  ${media.md} {
    ${({ big }) =>
      big &&
      css`
        flex-direction: column;
      `}
  }
`;

export const StarsCol = styled.div`
  display: flex;
  width: 100%;

  ${media.md} {
    flex-wrap: wrap;
  }
`;

export const StarsTitle = styled.h4`
  max-width: 580px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 36px;
  line-height: 118.5%;
  color: #fff;

  ${media.md} {
    font-size: 24px;
  }
`;

export const StarsText = styled.div`
  max-width: 580px;
  font-weight: 500;
  font-size: 16px;
  line-height: 157%;
  color: rgba(255, 255, 255, 0.54);

  ${media.md} {
    font-size: 14px;
  }
`;

type StarsItemProps = {
  big?: boolean;
};

export const StarsItem = styled.div<StarsItemProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px;
  border: 1px solid #2c2c2c;

  ${({ big }) =>
    big &&
    css`
      ${StarsTitle} {
        font-size: 42px;
      }

      ${StarsText} {
        font-size: 23px;
      }
    `}

  ${media.md} {
    min-height: 200px;
    padding: 40px 25px;

    ${({ big }) =>
      big &&
      css`
        ${StarsTitle} {
          font-size: 28px;
        }

        ${StarsText} {
          font-size: 17px;
        }
      `}
  }
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  margin: 110px 0;

  ${media.md} {
    display: grid;
    grid-gap: 30px;
    margin: 50px 0;
  }
`;

type SectionsItemProps = {
  reverse?: boolean;
};

export const SectionsItem = styled.div<SectionsItemProps>`
  display: flex;
  background: rgba(0, 0, 0, 0.26);

  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: row-reverse;
    `}

  ${media.md} {
    flex-direction: column;
  }
`;

export const SectionsImage = styled.img`
  flex-shrink: 0;
  width: 50%;
  object-fit: cover;

  ${media.md} {
    width: 100%;
  }
`;

export const SectionsBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 50%;
  padding: 80px 96px;

  ${media.md} {
    align-items: center;
    width: 100%;
    padding: 25px;
  }
`;

export const SectionsTitle = styled.h3`
  margin-bottom: 26px;
  font-weight: 300;
  font-size: 48px;
  line-height: 118.5%;
  color: #dbdbdb;

  ${media.md} {
    margin-bottom: 10px;
    font-size: 36px;
    text-align: center;
  }
`;

export const SectionsText = styled.div`
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 17px;
  line-height: 198.5%;
  color: #848484;

  ${media.md} {
    margin-bottom: 10px;
    font-size: 14px;
  }
`;
