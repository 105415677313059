import styled from "styled-components/macro";
import { misc } from "../../styles/constants";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  opacity: 0.28;
  transition: ${misc.transition};

  &:hover {
    opacity: 1;
  }
`;
