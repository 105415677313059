import { mediaMax } from "./helpers";

export const size = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

export const media = {
  sm: mediaMax(size.sm),
  md: mediaMax(size.md),
  lg: mediaMax(size.lg),
  xl: mediaMax(size.xl),
  xxl: mediaMax(size.xxl),
};

export const color = {
  white: "#fff",
  black: "#000",
  light: "#CACACA",
  dark: "#101010",
};

export const misc = {
  fontFamily: "Neue Machina, sans-serif",
  transition: "all 0.3s ease",
};
