import face1Img from "../../assets/images/face-1.jpg";
import face2Img from "../../assets/images/face-2.jpg";

import smileImg from "../../assets/images/emoji-smile.png";
import clappingImg from "../../assets/images/emoji-clapping.png";

export type Review = {
  pic: string;
  reaction: string;
  name: string;
  course: string;
  text: string;
};

export const reviews: Review[] = [
  {
    pic: face1Img,
    reaction: smileImg,
    name: "John Doe",
    course: "Manicure. Basic course.",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt lacinia, nisl nisl aliquam massa, nec ultrices nunc nisl euismod nunc. Sed euismod, nisl vel tincidunt lacinia, nisl nisl aliquam massa, nec ultrices nunc nisl euismod nunc.",
  },
  {
    pic: face2Img,
    reaction: clappingImg,
    name: "John Doe",
    course: "Manicure. Basic course.",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt lacinia, nisl nisl aliquam massa, nec ultrices nunc nisl euismod nunc. Sed euismod, nisl vel tincidunt lacinia, nisl nisl aliquam massa, nec ultrices nunc nisl euismod nunc.",
  },
  {
    pic: face1Img,
    reaction: smileImg,
    name: "John Doe",
    course: "Manicure. Basic course.",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt lacinia, nisl nisl aliquam massa, nec ultrices nunc nisl euismod nunc. Sed euismod, nisl vel tincidunt lacinia, nisl nisl aliquam massa, nec ultrices nunc nisl euismod nunc.",
  },
];
