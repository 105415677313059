import React from "react";
import MainTemplate from "../../templates/Main";
import schoolBgImg from "../../assets/images/school-bg.png";
import { gql, useQuery } from "@apollo/client";
import {
  Page,
  Header,
  Title,
  Desc,
  Grid,
  Item,
  ItemImage,
  ItemTitle,
  ItemDesc,
  ItemDate,
  NewLabel,
  HotLabel,
  LinkArrow,
} from "./styles";

type Course = {
  slug: string;
  title: string;
  shortText: string;
  date: string;
  hot: boolean;
  new: boolean;
  preview: {
    url: string;
  };
};

type CourseCollection = {
  items: Course[];
};

type CourseCollectionQuery = {
  courseCollection: CourseCollection;
};

const courseCollection = gql`
  query CourseCollection {
    courseCollection {
      items {
        slug
        title
        shortText
        date
        hot
        new
        preview {
          url
        }
      }
    }
  }
`;

const CoursesPage: React.FC = () => {
  const { data } = useQuery<CourseCollectionQuery>(courseCollection);

  return (
    <MainTemplate bgImg={schoolBgImg}>
      <Page>
        <Header>
          <Title>School</Title>
          <Desc>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            dapibus eget nibh id euismod. Sed sed orci eget risus commodo
            varius. Sed dapibus augue ac neque viverra efficitur. Proin
            porttitor urna ut augue maximus, vel consequat purus commodo.
          </Desc>
        </Header>
        {data && (
          <Grid>
            {data.courseCollection.items.map((item) => (
              <Item to={`/course/${item.slug}`} key={item.slug}>
                {item.preview && <ItemImage src={item.preview.url} />}

                <ItemTitle>{item.title}</ItemTitle>
                <ItemDesc>{item.shortText}</ItemDesc>
                <ItemDate>{item.date}</ItemDate>

                {item.new && <NewLabel />}
                {item.hot && <HotLabel />}

                <LinkArrow />
              </Item>
            ))}
          </Grid>
        )}
      </Page>
    </MainTemplate>
  );
};

export default CoursesPage;
