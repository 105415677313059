import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { ReactComponent as HotLabelIcon } from "../../assets/images/hot-label.svg";
import { ReactComponent as NewLabelIcon } from "../../assets/images/new-label.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/link-arrow.svg";
import { media, misc } from "../../styles/constants";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 100px;

  ${media.md} {
    flex-direction: column;
    margin-bottom: 40px;
  }
`;

export const Title = styled.h1`
  margin-top: 20px;
  margin-right: 120px;
  font-weight: 800;
  font-size: 149px;
  line-height: 144px;
  color: #ffffff;

  ${media.md} {
    margin: 0 0 20px;
    font-weight: 800;
    font-size: 64px;
    line-height: 62px;
    text-align: center;
  }
`;

export const Desc = styled.div`
  max-width: 900px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 227.9%;
  color: #737373;

  ${media.md} {
    text-align: center;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border: 1px solid #1c1c1c;

  ${media.md} {
    grid-template-columns: 1fr;
  }
`;

export const ItemImage = styled.img`
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  object-fit: cover;

  ${media.md} {
    height: 180px;
  }
`;

export const NewLabel = styled(NewLabelIcon)`
  position: absolute;
  top: 20px;
  right: 20px;

  ${media.md} {
    width: 54px;
    height: 54px;
  }
`;

export const HotLabel = styled(HotLabelIcon)`
  position: absolute;
  top: 20px;
  right: 20px;

  ${media.md} {
    width: 54px;
    height: 54px;
  }
`;

export const ItemTitle = styled.h2`
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 23px;
  line-height: 22px;
  color: #ffffff;
`;

export const ItemDesc = styled.div`
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 178.9%;
  color: #535353;
`;

export const ItemDate = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 178.9%;
  color: #398167;
`;

export const LinkArrow = styled(ArrowIcon)`
  position: absolute;
  right: 20px;
  bottom: 30px;
  transition: ${misc.transition};
`;

export const Item = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #1c1c1c;
  transition: ${misc.transition};
  text-decoration: none;

  &:hover {
    border: 1px solid #398167;

    ${LinkArrow} {
      path {
        fill: #398167;
      }
    }
  }
`;
