import { createGlobalStyle } from "styled-components";
import { color, misc } from "./constants";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${misc.fontFamily};
  }
  
  body {
    color: ${color.light};
    background: ${color.dark};
  }
`;
