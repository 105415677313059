import StartPage from "../pages/start";
import CoursesPage from "../pages/courses";
import CoursePage from "../pages/course";
import NotFoundPage from "../pages/notFound";

export const routes = [
  { path: "/", element: <StartPage /> },
  { path: "/courses", element: <CoursesPage /> },
  { path: "/course/:slug", element: <CoursePage /> },
  { path: "*", element: <NotFoundPage /> },
];
