import React from "react";
import TextLink from "../../components/TextLink";
import MainTemplate from "../../templates/Main";
import studioImg from "../../assets/images/studio.jpg";
import schoolImg from "../../assets/images/school.jpg";
import shopImg from "../../assets/images/shop.jpg";
import {
  Page,
  Intro,
  IntroBody,
  IntroTitle,
  IntroTitleStart,
  IntroText,
  IntroSignature,
  IntroMobilePhoto,
  IntroDesktopPhoto,
  Stars,
  StarsRow,
  StarsCol,
  StarsItem,
  StarsTitle,
  StarsText,
  Sections,
  SectionsItem,
  SectionsImage,
  SectionsBody,
  SectionsTitle,
  SectionsText,
} from "./styles";
import Reviews from "../../components/Reviews";

const StartPage: React.FC = () => {
  return (
    <MainTemplate>
      <Page>
        <Intro>
          <IntroBody>
            <IntroTitle>
              <IntroTitleStart>Hello! I am</IntroTitleStart>
              Alona Ivakha
            </IntroTitle>
            <IntroMobilePhoto />
            <IntroText>
              I am an expert and instructor in the nail industry! My experience
              and professionalism will bring the master to a successful level in
              the shortest possible time!
              <TextLink to="/" text="Contact with me" />
              <IntroSignature />
            </IntroText>
          </IntroBody>
          <IntroDesktopPhoto />
        </Intro>

        <Stars>
          <StarsRow>
            <StarsCol>
              <StarsItem>
                <StarsTitle>17 year</StarsTitle>
                <StarsText>Expert in the nail industry</StarsText>
              </StarsItem>
              <StarsItem>
                <StarsTitle>6 year</StarsTitle>
                <StarsText>I am a practicing instructor</StarsText>
              </StarsItem>
            </StarsCol>
            <StarsCol>
              <StarsItem>
                <StarsTitle>10.000+</StarsTitle>
                <StarsText>Satisfied customers</StarsText>
              </StarsItem>
              <StarsItem>
                <StarsTitle>200+</StarsTitle>
                <StarsText>Professionally trained student</StarsText>
              </StarsItem>
            </StarsCol>
          </StarsRow>
          <StarsRow big>
            <StarsCol>
              <StarsItem big>
                <StarsTitle>16 medals and the Grand Prix Cup!</StarsTitle>
                <StarsText>
                  Multiple champion of Ukraine and international championships
                </StarsText>
              </StarsItem>
            </StarsCol>
            <StarsCol>
              <StarsItem big>
                <StarsTitle>50+</StarsTitle>
                <StarsText>
                  Completed courses, trainings, seminars (profile, marketing,
                  sales, doing business, psychology)
                </StarsText>
              </StarsItem>
            </StarsCol>
          </StarsRow>
        </Stars>

        <Sections>
          <SectionsItem>
            <SectionsImage src={studioImg} />
            <SectionsBody>
              <SectionsTitle>Studio</SectionsTitle>
              <SectionsText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                commodo mi eget rhoncus tempus. In eu sodales mauris, sit amet
                aliquam turpis.
              </SectionsText>
              <TextLink to="/" text="Read more" />
            </SectionsBody>
          </SectionsItem>
          <SectionsItem reverse>
            <SectionsImage src={schoolImg} />
            <SectionsBody>
              <SectionsTitle>School</SectionsTitle>
              <SectionsText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                commodo mi eget rhoncus tempus. In eu sodales mauris, sit amet
                aliquam turpis.
              </SectionsText>
              <TextLink to="/" text="Read more" />
            </SectionsBody>
          </SectionsItem>
          <SectionsItem>
            <SectionsImage src={shopImg} />
            <SectionsBody>
              <SectionsTitle>Shop</SectionsTitle>
              <SectionsText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                commodo mi eget rhoncus tempus. In eu sodales mauris, sit amet
                aliquam turpis.
              </SectionsText>
              <TextLink to="/" text="Read more" />
            </SectionsBody>
          </SectionsItem>
        </Sections>

        <Reviews />
      </Page>
    </MainTemplate>
  );
};

export default StartPage;
