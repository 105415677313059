import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MobileMenu from "../../components/MobileMenu";
import { MainTemplateProps } from "./types";
import { Container, Inner, Body } from "./styles";

const MainTemplate: React.FC<MainTemplateProps> = ({ children, bgImg }) => {
  return (
    <Container bgImg={bgImg}>
      <Inner>
        <Header />
        <Body>{children}</Body>
        <Footer />
        <MobileMenu />
      </Inner>
    </Container>
  );
};

export default MainTemplate;
