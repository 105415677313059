import styled from "styled-components/macro";
import logoImg from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { color, misc, media } from "../../styles/constants";

export const Container = styled.header`
  display: flex;
  align-items: center;
  padding: 60px 0 50px;

  ${media.md} {
    justify-content: center;
    padding: 50px 0 0;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const Logo = styled.img.attrs({
  src: logoImg,
})`
  ${media.md} {
    width: 115px;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;

  ${media.md} {
    display: none;
  }
`;

export const NavLink = styled(Link)`
  position: relative;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  color: #686868;
  text-decoration: none;
  transition: ${misc.transition};

  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    display: block;
    width: 0;
    height: 1px;
    background-color: #30735b;
    transition: ${misc.transition};
  }

  &:not(:last-child) {
    margin-right: 38px;
  }

  &:hover {
    color: ${color.white};

    &::after {
      width: 100%;
    }
  }
`;

export const Contacts = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  ${media.md} {
    display: none;
  }
`;

export const PhoneLink = styled.a`
  margin-top: 2px;
  margin-left: 20px;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  color: #bfbfbf;
  text-decoration: none;
  transition: ${misc.transition};

  &:hover {
    color: ${color.white};
  }
`;
